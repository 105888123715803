import { defineStore } from 'pinia'
import { globalApi } from '@/api/global'
import { isNumber, isString } from 'lodash-es'
import { localReference } from '@/types/local-reference'
import { Stoppable, useTimeoutFn } from '@vueuse/core'
import { Model } from '@/types/model'
import { noticeApi } from '@/api/notice'
const referenceColorMap = {
  green: '#00B42A',
  orange: '#FF9A2E',
  red: '#F53F3F',
}

interface GlobalState {

  /** 枚举类型 */
  reference: Nullable<
    Partial<{ [key in Reference.ReferenceKey]: Reference.ReferenceItem[] }>
  >;
  referenceColorMap: typeof referenceColorMap;

  notifications: Model.Notification;
  messages: Model.MessageItem[];
  historyMessageList: Model.MessageItem[],
  unReadMessageList: Model.MessageItem[],
  messageCountMap: {
    [k: string]: number;
  }
}

let timer: Nullable<Stoppable>

/** app 全局信息 */
export const useAppStore = defineStore('App', {
  state: (): GlobalState => ({
    reference: null,

    /** reference 中配置的颜色转换器 */
    referenceColorMap,

    notifications: {} as Model.Notification,

    // 顶部小铃铛的消息列表
    messages: [],
    historyMessageList: [],
    unReadMessageList: [],
    messageCountMap:{}
  }),

  actions: {

    /** 获取枚举值详情 */
    getReferenceLabel(
      referenceKey: Reference.ReferenceKey,
      value: number
    ): string {
      const referenceItem = this.getReferenceItems(referenceKey)
      if (!isNumber(value) && !isString(value)) {
        return ''
      }
      return (
        referenceItem.find(item => item.value.toString() === value.toString())
          ?.label || ''
      )
    },

    /** 获取枚举值列表 */
    getReferenceItems(referenceKey: Reference.ReferenceKey): Reference.ReferenceItem[] {
      return this.reference?.[referenceKey] || []
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsValueMap(referenceKey: Reference.ReferenceKey): Record<number, Reference.ReferenceItem> {
      let valueMap: Record<number, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        valueMap[item.value] = toRaw(item)
      })
      return valueMap
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsLabelMap(referenceKey: Reference.ReferenceKey): Record<string, Reference.ReferenceItem> {
      let labelMap: Record<string, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        labelMap[item.label] = toRaw(item)
      })
      return labelMap
    },

    /** 获取枚举值常量 map */
    getReferenceConstantMap<T extends Reference.ReferenceKey>(referenceKey: T): Reference.ConstantKeyMap[T] {
      let constantMap = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        constantMap[item.key!] = toRaw(item)
      })
      return constantMap as Reference.ConstantKeyMap[T]
    },

    /** 重置枚举值 */
    async refreshReference() {
      let res = await globalApi.getGlobalData()
      Object.keys(res).forEach(key => {
        if (Array.isArray(res[key])) {
          res[key]?.forEach(item => {
            item.label = item.label ?? item?.name
            item.value = item.value ?? item?.id
          })
        }
      })
      this.reference = res
      this.reference = Object.assign(localReference, this.reference)

      /** 设置枚举颜色值 */
      // 统一管理颜色
      const greenColor = '#1eb842'// 绿色
      const greenBgColor = '#c0efd0'// 绿色背景色
      const redColor = '#f2503cff'// 红色
      const redBgColor = '#f2503c1f'// 红色背景色
      const orangeColor = '#ff7214'// 橙色
      const orangeBgColor = '#fff0e6'// 橙色背景色
      const blueColor = '#2a9df3'// 蓝色
      const blueBgColor = '#e5f3fe'// 蓝色背景色
      const grayColor = '#A8A8A8'
      const grayBgColor = '#ededed'
      const purpleColor = '#8A89CBFF'// 紫色
      const purpleBgColor = '#8A89CB1A'// 紫色背景色

      const followStatusesEnum = this.getReferenceConstantMap('followRealStatuses')
      followStatusesEnum.COMMUNICATE.color = '#409EFF'

      followStatusesEnum.COMMUNICATE.color = '#409EFF' // 初步沟通 - 蓝色
      followStatusesEnum.COMMUNICATE_REJECT.color = '#F56C6C' // 初步沟通-否决人选 - 红色
      followStatusesEnum.COMMUNICATE_CANCEL.color = '#999999' // 初步沟通-人选放弃 - 灰色
      followStatusesEnum.RECOMMEND.color = '#67C23A' // 推荐 - 绿色

      followStatusesEnum.BOOK_AN_INTERVIEW.color = '#E6A23C' // 预约面试 - 橙色
      followStatusesEnum.RECOMMEND_REJECT.color = '#CC0033' // 推荐-否决人选 - 深红色
      followStatusesEnum.RECOMMEND_CANCEL.color = '#FF6666' // 推荐-人选放弃 - 粉红色
      followStatusesEnum.ATTEND.color = '#33CC33' // 到场 - 亮绿色
      followStatusesEnum.NO_ATTEND.color = '#FF3300' // 不到场 - 红橙色
      followStatusesEnum.PASS_INTERVIEW.color = '#66CC99' // 通过 - 浅绿色
      followStatusesEnum.NO_PASS_INTERVIEW.color = '#FF0033' // 不通过 - 鲜红色
      followStatusesEnum.ACCEPT_OFFER.color = '#3366FF' // 接offer - 深蓝色
      followStatusesEnum.GIVE_UP_OFFER.color = '#FF6600' // 放弃offer - 橙色
      followStatusesEnum.ENTRY.color = '#339933' // 入职 - 深绿色
      followStatusesEnum.GIVE_UP_ENTRY.color = '#FF9900' // 放弃入职 - 深橙色
      followStatusesEnum.OVER_INSURED.color = '#33CC99' // 过保 - 青绿色
      followStatusesEnum.LEAVE_IN_INSURED.color = '#FFCC00' // 保内离职 - 黄色
      followStatusesEnum.LEAVE_OUT_INSURED.color = '#FF9900' // 保外离职 - 深橙色

      const frontendAccountStatusLocalEnum = this.getReferenceConstantMap('frontendAccountStatusLocalEnum')
      frontendAccountStatusLocalEnum.ACTIVE.color = greenColor
      frontendAccountStatusLocalEnum.FREEZE.icon = redColor


      const showOrhiddenLocalEnum = this.getReferenceConstantMap('showOrHidden')
      showOrhiddenLocalEnum.HIDEEN.color = redColor
      showOrhiddenLocalEnum.HIDEEN.bgColor = redBgColor
      showOrhiddenLocalEnum.SHOW.color = greenColor
      showOrhiddenLocalEnum.SHOW.bgColor = greenBgColor
      const urgencyDegreeEnum = this.getReferenceConstantMap('urgencyDegreeEnum')
      urgencyDegreeEnum.EXTRA_URGENT.color = redColor
      urgencyDegreeEnum.EXTRA_URGENT.icon = 'icon-huo'
      urgencyDegreeEnum.EXIGENCY.color = orangeColor
      urgencyDegreeEnum.EXIGENCY.icon = 'icon-huo'
      urgencyDegreeEnum.NORMAL.color = greenColor
      urgencyDegreeEnum.NORMAL.icon = 'icon-bujinji'

      const jobStatusEnum = this.getReferenceConstantMap('jobStatusEnum')
      jobStatusEnum.UNSTART.color = orangeColor
      jobStatusEnum.START.color = blueColor
      jobStatusEnum.FINISH.color = greenColor


      const attachmentStatusesEnum = this.getReferenceConstantMap('attachmentStatuses')
      attachmentStatusesEnum?.FAIL && (attachmentStatusesEnum.FAIL.color = redColor)
      attachmentStatusesEnum?.FAIL && (attachmentStatusesEnum.FAIL.bgColor = redBgColor)
      attachmentStatusesEnum?.SUCCESS && (attachmentStatusesEnum.SUCCESS.color = greenColor)
      attachmentStatusesEnum?.SUCCESS && (attachmentStatusesEnum.SUCCESS.bgColor = greenBgColor)
      attachmentStatusesEnum?.WAIT && (attachmentStatusesEnum.WAIT.color = orangeColor)
      attachmentStatusesEnum?.WAIT && (attachmentStatusesEnum.WAIT.bgColor = orangeBgColor)
      attachmentStatusesEnum?.handling && (attachmentStatusesEnum.handling.color = blueColor)
      attachmentStatusesEnum?.handling && (attachmentStatusesEnum.handling.bgColor = blueBgColor)

      // 审批状态
      const approvalStatusEnum = this.getReferenceConstantMap('approvalStatusEnum')
      approvalStatusEnum?.UNDER_WAY && (approvalStatusEnum.UNDER_WAY.color = orangeColor)
      approvalStatusEnum?.UNDER_WAY && (approvalStatusEnum.UNDER_WAY.bgColor = orangeBgColor)
      approvalStatusEnum?.COMPLETED && (approvalStatusEnum.COMPLETED.color = greenColor)
      approvalStatusEnum?.COMPLETED && (approvalStatusEnum.COMPLETED.bgColor = greenBgColor)
      approvalStatusEnum?.REJECT && (approvalStatusEnum.REJECT.color = redColor)
      approvalStatusEnum?.REJECT && (approvalStatusEnum.REJECT.bgColor = redBgColor)
      approvalStatusEnum?.CANCELED && (approvalStatusEnum.CANCELED.color = redColor)
      approvalStatusEnum?.CANCELED && (approvalStatusEnum.CANCELED.bgColor = redBgColor)
      approvalStatusEnum?.TRANSFER && (approvalStatusEnum.TRANSFER.color = blueColor)
      approvalStatusEnum?.TRANSFER && (approvalStatusEnum.TRANSFER.bgColor = blueBgColor)
      approvalStatusEnum.UNDER_WAY && (approvalStatusEnum.UNDER_WAY.icon = 'icon-jinhangzhong')
      approvalStatusEnum.INVOICE && (approvalStatusEnum.INVOICE.icon = 'icon-jinhangzhong')
      approvalStatusEnum.REVIEW && (approvalStatusEnum.REVIEW.icon = 'icon-jinhangzhong')
      approvalStatusEnum.REPLENISH && (approvalStatusEnum.REPLENISH.icon = 'icon-jinhangzhong')
      approvalStatusEnum.CANCELED && (approvalStatusEnum.CANCELED.icon = 'icon-shanchu')
      approvalStatusEnum.REJECT && (approvalStatusEnum.REJECT.icon = 'icon-shanchu')
      approvalStatusEnum.VOID && (approvalStatusEnum.VOID.icon = 'icon-shanchu')
      approvalStatusEnum.START && (approvalStatusEnum.START.icon = 'icon-tijiaochenggong')
      approvalStatusEnum.REVERSE && (approvalStatusEnum.REVERSE.icon = 'icon-tijiaochenggong')
      approvalStatusEnum.COPY && (approvalStatusEnum.COPY.icon = 'icon-tijiaochenggong')
      approvalStatusEnum.INVOICED && (approvalStatusEnum.INVOICED.icon = 'icon-tijiaochenggong')
      approvalStatusEnum.COMPLETED && (approvalStatusEnum.COMPLETED.icon = 'icon-tijiaochenggong')
      approvalStatusEnum.TRANSFER && (approvalStatusEnum.TRANSFER.icon = 'icon-user-shared-fill1')
      approvalStatusEnum.COMMENT && (approvalStatusEnum.COMMENT.icon = 'icon-pinglun')


      // 结算状态
      const settlementStatusEnum = this.getReferenceConstantMap('settlementStatusEnum')
      settlementStatusEnum?.SETTLED && (settlementStatusEnum.SETTLED.color = greenColor)
      settlementStatusEnum?.SETTLED && (settlementStatusEnum.SETTLED.bgColor = greenBgColor)
      settlementStatusEnum?.SETTLING && (settlementStatusEnum.SETTLING.color = orangeColor)
      settlementStatusEnum?.SETTLING && (settlementStatusEnum.SETTLING.bgColor = orangeBgColor)
      settlementStatusEnum?.SETTLE_CANCEL && (settlementStatusEnum.SETTLE_CANCEL.color = redColor)
      settlementStatusEnum?.SETTLE_CANCEL && (settlementStatusEnum.SETTLE_CANCEL.bgColor = redBgColor)
      settlementStatusEnum?.SETTLE_REJECT && (settlementStatusEnum.SETTLE_REJECT.color = redColor)
      settlementStatusEnum?.SETTLE_REJECT && (settlementStatusEnum.SETTLE_REJECT.bgColor = redBgColor)
      settlementStatusEnum?.UNSETTLED && (settlementStatusEnum.UNSETTLED.color = redColor)
      settlementStatusEnum?.UNSETTLED && (settlementStatusEnum.UNSETTLED.bgColor = redBgColor)

      // 开票状态
      const invoiceStatusEnum = this.getReferenceConstantMap('invoiceStatusEnum')
      invoiceStatusEnum?.UNDER_WAY && (invoiceStatusEnum.UNDER_WAY.color = blueColor)
      invoiceStatusEnum?.UNDER_WAY && (invoiceStatusEnum.UNDER_WAY.bgColor = blueBgColor)
      invoiceStatusEnum?.IN_PREPARATION && (invoiceStatusEnum.IN_PREPARATION.color = orangeColor)
      invoiceStatusEnum?.IN_PREPARATION && (invoiceStatusEnum.IN_PREPARATION.bgColor = orangeBgColor)
      invoiceStatusEnum?.COMPLETED && (invoiceStatusEnum.COMPLETED.color = greenColor)
      invoiceStatusEnum?.COMPLETED && (invoiceStatusEnum.COMPLETED.bgColor = greenBgColor)
      invoiceStatusEnum?.REJECTED && (invoiceStatusEnum.REJECTED.color = redColor)
      invoiceStatusEnum?.REJECTED && (invoiceStatusEnum.REJECTED.bgColor = redBgColor)

      // 回款状态
      const refundStatusEnum = this.getReferenceConstantMap('refundStatusEnum')
      refundStatusEnum?.UNDER_WAY && (refundStatusEnum.UNDER_WAY.color = blueColor)
      refundStatusEnum?.UNDER_WAY && (refundStatusEnum.UNDER_WAY.bgColor = blueBgColor)
      refundStatusEnum?.IN_PREPARATION && (refundStatusEnum.IN_PREPARATION.color = orangeColor)
      refundStatusEnum?.IN_PREPARATION && (refundStatusEnum.IN_PREPARATION.bgColor = orangeBgColor)
      refundStatusEnum?.COMPLETED && (refundStatusEnum.COMPLETED.color = greenColor)
      refundStatusEnum?.COMPLETED && (refundStatusEnum.COMPLETED.bgColor = greenBgColor)
      refundStatusEnum?.REJECTED && (refundStatusEnum.REJECTED.color = redColor)
      refundStatusEnum?.REJECTED && (refundStatusEnum.REJECTED.bgColor = redBgColor)
    },

    freshMessage() {
      // 获取消息
      noticeApi.getFresh().then(res => {
        this.historyMessageList = res?.list ?? []
        this.unReadMessageList = res?.unreadList ?? []
      })
    },
    freshSubscript() {
      noticeApi.getSubscript().then((res) => {
        this.messageCountMap = res
      })
    },
    // 未处理消息
    refreshTimerData(loop?: boolean) {
      // this.freshMessage()
      // 获取数量角标
      this.freshSubscript()
      // if (loop && import.meta.env.PROD) {
      timer = useTimeoutFn(() => this.refreshTimerData(true), 10000)
      // }
    },

    // 点击消息跳转到对应详情
    clickMessageToDetail(record) {
      let routerName = ''
      let messageParams = {}
      switch (record.messageEventType) {

        default:
          routerName = ''
      }
      // if (routerName) {
      //   // 设置消息已读
      //   await messageApi.singleMessageRead(record.id)
      //   router.push({
      //     name: routerName,
      //     params: messageParams
      //   })
      // }
    },

    // 关闭定时器
    async stopTimer() {
      await timer?.stop()
      timer = null
    },
  },
})
